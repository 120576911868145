<template>
  <div>
    <footer class="footer">
      <div class="footer-card">
        <div class="footer-card-body">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-lg-10 col-xl-6 mx-auto">
                <div class="build-box">
                  <div class="build-image">
                    <img
                      src="../assets/logo.png"
                      alt=""
                      class="img-fluid"
                      loading="lazy"
                      style="max-width:230px;"
                    />
                  </div>
                  <!--build-image-->

                  <div class="build-info">
                    <h3 class="mb-0 pb-4">Collaborate and Create Together</h3>

                    <div class="build-button">
                      <button class="btn btn-primary" id="building-button">
                        Start Building
                        <span
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"
                            /></svg
                        ></span>
                      </button>
                      <button
                        class="btn btn-outline-secondary"
                        id="ecosystem-button"
                      >
                        Explore Ecosystem
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                    <!--build-button-->
                  </div>
                  <!--build-info-->
                </div>
                <!--build-box-->
              </div>
              <!--col-md-6 col-lg-6 col-xl-6 mx-auto-->

              <div class="col-md-12 col-lg-12 col-xl-12">
                <div class="row inner-row">
                  <div class="col-md-6 col-lg-6 col-xl-6">
                    <div class="links-list">
                      <ul class="list-box">
                        <li>
                          <router-link to=""> Ecosystem </router-link>
                        </li>
                        <li>
                          <router-link to=""> Participate </router-link>
                        </li>
                        <li>
                          <router-link to=""> Community </router-link>
                        </li>
                      </ul>
                    </div>
                    <!--links-list-->
                  </div>
                  <!--col-md-6 col-lg-6 col-xl-6-->

                  <div class="col-md-6 col-lg-6 col-xl-6">
                    <div class="copyright-text">
                      <p class="mb-0">Copyright 2023 © GDCC</p>
                    </div>
                    <!--copyright-text-->
                  </div>
                  <!--col-md-6 col-lg-6 col-xl-6-->
                </div>
                <!--row inner-row-->
              </div>
              <!--col-md-12 col-lg-12 col-xl-12-->
            </div>
            <!--row-->
          </div>
          <!--container-->
        </div>
        <!--footer-card-body-->
      </div>
      <!--footer-card-->
    </footer>
    <!--footer-->
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.footer {
  padding: 80px 0;
}

.footer-card {
  position: relative;
  border-radius: 106px 106px 0 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-position: 0 0;
  background-image: radial-gradient(
    75% 75% at 51% 7%,
    hsla(0, 0%, 87%, 0.839) 0,
    rgba(89, 89, 89, 0.82) 35%,
    #000 73%
  );
  padding-top: 1px;
}

.footer-card-body {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 106px 106px 0 0;
  background-color: var(--black);
}

.build-box {
  margin-top: 90px;
  margin-bottom: 90px;
}

.build-image {
  text-align: center;
  margin-bottom: 25px;
}

.build-info {
  text-align: center;
}

.build-info h3 {
  font-size: 25px;
  color: var(--white);
  font-weight: 600;
}

.build-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.build-button button.btn {
  min-height: 50px;
  min-width: 160px;
  font-size: 16px;
  font-family: "Poppins";
  border-radius: 10px;
  color: var(--white);
  font-weight: 500;
}

.build-button button.btn span svg {
  fill: var(--white);
  margin-left: 7px;
}

#building-button {
  background: var(--pink);
  border: none;
  transition: all 0.5s ease;
}

#building-button:hover {
  background: var(--purple);
  transition: all 0.5s ease;
}

button#ecosystem-button {
  transition: all 0.5s ease;
  border-color: var(--white);
}

button#ecosystem-button:hover {
  transition: all 0.5s ease;
  background-color: var(--purple);
  border-color: var(--purple);
}

.row.inner-row {
  padding-top: 40px;
  border-top: 1px solid var(--border);
}

.links-list ul.list-box {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 40px;
}

.links-list ul.list-box li a {
  text-decoration: none;
  font-size: 16px;
  color: var(--white);
  text-transform: capitalize;
  font-family: "Poppins";
}

.copyright-text {
    text-align: end;
}

.copyright-text p {
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins";
    color: var(--grey);
}


@media all and (min-width: 320px ) and  (max-width: 767px) {
  .footer {
    padding-bottom: 20px;
  }

  .footer-card {
    border-radius: 70px 70px 0 0;
  }

  .footer-card-body {
    border-radius: 70px 70px 0 0;
  }

  .footer-card

  .build-button {
    display: block;
  }

  #building-button {
    margin-bottom: 20px;
  } 

  .build-info h3 {
    font-size: 20px;
  }

  .links-list ul.list-box {
    gap: 0;
    justify-content: space-between;
    margin-bottom: 20px;
 }

 .copyright-text {
  text-align: center;

 }

 
}
</style>
