<template>
  <div>
    <section class="banner-sec">
      <Banner
        title="Hybrid Crypto Revolution peer-to-peer"
        para="Experience the future of transactions with Hybrid Crypto Revolution Peer-to-Peer. Embrace secure, decentralized exchanges through innovative cryptographic technology, driving a new era of digital trust and efficiency."
        :banner_image="Banner_Image"
      />
    </section>
    <!--banner-sec-->

    <section class="scalebale-sec">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="scaleable-info">
              <h3 class="mb-0">
                Flexible. <br />
                Well-Tested. <br />
                Limitless.
              </h3>
            </div>
            <!--scaleable-info-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->

          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="scaleable-list">
              <ul class="scale-list">
                <li>
                  <div class="scale-box">
                    <h2 class="mb-0 pb-2">7,914,505</h2>
                    <span>Total Blocks</span>
                  </div>
                </li>

                <li>
                  <div class="scale-box">
                    <h2 class="mb-0 pb-2">4,238,647</h2>
                    <span>Total Transactions</span>
                  </div>
                </li>

                <li>
                  <div class="scale-box">
                    <h2 class="mb-0 pb-2">3,310,694</h2>
                    <span>Total Addresses</span>
                  </div>
                </li>
              </ul>
            </div>
            <!--scaleable-list-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->
        </div>
        <!--row-->
      </div>
      <!--container-->
    </section>
    <!--about-sec-->

    <section class="web-three-sec">
      <div class="container">
        <div class="row mb-5">
          <div class="col-md-12 col-lg-12 col-xl-12">
            <Heading subtitle="Features" title="Web3 That Works" />
          </div>
          <!--col-md-12 col-lg-12 col-xl-12-->

          <div class="col-md-12 col-lg-6 col-xl-8 mb-md-3">
            <div class="card-box">
              <div class="card-body">
                <div class="box-shape"></div>
                <div class="web-main-box">
                  <div class="icon-box">
                    <img
                      src="../assets/images/home/web-icon-1.png"
                      alt="web"
                      class="img-fluid"
                      loading="lazy"
                    />
                  </div>
                  <!--icon-box-->

                  <div class="icon-info">
                    <h4 class="mb-0 pb-3">The #1 Web3 Gaming Community</h4>

                    <p class="mb-0">
                      Join the evolution of gaming with our vibrant Web3
                      community, where
                      <br />
                      innovation meets immersive gameplay.
                    </p>
                  </div>
                  <!--icon-info-->
                </div>
                <!--web-main-box-->
              </div>
              <!--card-body-->
            </div>
            <!--card-box-->
          </div>
          <!--col-md-8 col-lg-8 col-xl-8-->

          <div class="col-md-12 col-lg-6 col-xl-4">
            <div class="card-box">
              <div class="card-body">
                <div class="box-shape"></div>
                <div class="web-main-box">
                  <div class="icon-box">
                    <img
                      src="../assets/images/home/web-icon-2.png"
                      alt="web"
                      class="img-fluid"
                      loading="lazy"
                    />
                  </div>
                  <!--icon-box-->

                  <div class="icon-info">
                    <h4 class="mb-0 pb-3">Battle-Tested</h4>

                    <p class="mb-0">
                      Proven through challenges, our solutions are Battle-Tested
                      for reliability, ready to excel in real-world scenarios
                    </p>
                  </div>
                  <!--icon-info-->
                </div>
                <!--web-main-box-->
              </div>
              <!--card-body-->
            </div>
            <!--card-box-->
          </div>
          <!--col-md-4 col-lg-4 col-xl-4-->
        </div>
        <!--row-->

        <div class="row">
          <div class="col-md-12 col-lg-6 col-xl-4 mb-md-3">
            <div class="card-box">
              <div class="card-body">
                <div class="box-shape"></div>
                <div class="web-main-box">
                  <div class="icon-box">
                    <img
                      src="../assets/images/home/web-icon-3.png"
                      alt="web"
                      class="img-fluid"
                      loading="lazy"
                    />
                  </div>
                  <!--icon-box-->

                  <div class="icon-info">
                    <h4 class="mb-0 pb-3">Cost Optimized</h4>

                    <p class="mb-0">
                      Achieve more with less through our Cost Optimized
                      solutions, maximizing efficiency without compromising
                      quality.
                    </p>
                  </div>
                  <!--icon-info-->
                </div>
                <!--web-main-box-->
              </div>
              <!--card-body-->
            </div>
            <!--card-box-->
          </div>
          <!--col-md-4 col-lg-4 col-xl-4-->

          <div class="col-md-12 col-lg-6 col-xl-8">
            <div class="card-box">
              <div class="card-body">
                <div class="box-shape"></div>
                <div class="web-main-box">
                  <div class="icon-box">
                    <img
                      src="../assets/images/home/web-icon-4.png"
                      alt="web"
                      class="img-fluid"
                      loading="lazy"
                    />
                  </div>
                  <!--icon-box-->

                  <div class="icon-info">
                    <h4 class="mb-0 pb-3">Seamless Deployment</h4>

                    <p class="mb-0">
                      Experience effortless implementation with Seamless
                      Deployment, ensuring smooth integration <br />
                      and immediate functionality for your needs.
                    </p>
                  </div>
                  <!--icon-info-->
                </div>
                <!--web-main-box-->
              </div>
              <!--card-body-->
            </div>
            <!--card-box-->
          </div>
          <!--col-md-8 col-lg-8 col-xl-8-->
        </div>
        <!--row-->
      </div>
      <!--container-->
    </section>
    <!--web-three-sec-->

    <section class="solutions-sec">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12">
            <Heading subtitle="Solutions" title="Technology that Scales" />
          </div>
          <!--col-md-12 col-lg-12 col-xl-12-->

          <div
            class="col-md-6 col-lg-6 col-xl-6 mb-4"
            v-for="(solution, index) in SolutionsData"
            :key="index"
          >
            <div class="card-box">
              <div class="card-body">
                <div class="solution-box">
                  <div class="solution-icon-box">
                    <img
                      :src="require(`../assets/images/home/${solution.icon}`)"
                      alt="solutions icon"
                      class="img-fluid"
                      loading="lazy"
                    />
                  </div>
                  <!--solution-icon-box-->

                  <div class="solution-icon-info">
                    <h3 class="mb-0 pb-3">{{ solution.title }}</h3>
                    <p class="mb-0" v-html="solution.para"></p>
                  </div>
                  <!--solution-icon-info-->
                </div>
                <!--solutions-box-->
              </div>
              <!--card-body-->
            </div>
            <!--card-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->
        </div>
        <!--row-->
      </div>
      <!--container-->
    </section>
    <!--solutions-sec-->

    <section class="contribute-sec">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 text-center">
            <Heading subtitle="Participate" title="Contribute to the Network" />
          </div>
          <!--col-md-12 col-lg-12 col-xl-12-->

          <div class="col-md-12 col-lg-12 col-xl-11 mx-auto">
            <div class="card-box">
              <div class="card-body">
                <div class="row inner-card-row justify-content-between">
                  <div
                    class="col-md-5 col-lg-5 col-xl-5 mb-5 mb-md-0 mb-lg-0 mb-xl-0"
                  >
                    <div class="contribute-main-box">
                      <h4 class="mb-0 pb-3">Become a Validator</h4>
                      <p class="mb-0 pb-3">
                        Run a Validator node to secure the network and <br />
                        earn rewards.
                      </p>

                      <button class="btn btn-outline-secondary shadow-none">
                        Learn More
                      </button>
                    </div>
                    <!--contribute-main-box-->
                  </div>
                  <!--col-md-5 col-lg-5 col-xl-5-->

                  <div class="col-md-1 col-lg-1 col-xl-1 offset-xl-1">
                    <div class="border-box"></div>
                  </div>

                  <div class="col-md-5 col-lg-5 col-xl-5">
                    <div class="contribute-main-box">
                      <h4 class="mb-0 pb-3">Become a Delegator</h4>
                      <p class="mb-0 pb-3">
                        Delegate your stake to validators to <br />
                        earn rewards.
                      </p>

                      <button class="btn btn-outline-secondary shadow-none">
                        Learn More
                      </button>
                    </div>
                    <!--contribute-main-box-->
                  </div>
                  <!--col-md-5 col-lg-5 col-xl-5-->
                </div>
                <!--row inner-card-row-->
              </div>
              <!--card-body-->
            </div>
            <!--card-box-->
          </div>
          <!--col-md-12 col-lg-12 col-xl-12-->
        </div>
        <!--row-->
      </div>
      <!--container-->
    </section>
    <!--contribute-sec-->

    <section class="partners-sec">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 text-center">
            <Heading subtitle="Partners" title="Build with the Best" />
          </div>
          <!--col-md-12 col-lg-12 col-xl-12-->
        </div>
        <!--row-->
      </div>
      <!--container-->

      <div
        class="wrapper"
        :id="img.id"
        v-for="(img, index) in ImageData"
        :key="index"
        :style="{
          backgroundImage:
            'url(' + require(`../assets/images/home/${img.icon}`) + ')',
        }"
      ></div>
      <!--wrapper-->

      <div class="partner-shape"></div>
    </section>
    <!--partners-sec-->
  </div>
</template>

<script>
import Solutionjson from "../assets/Json/Solutions.json";
import Heading from "../components/Heading.vue";
import Banner from "../components/BannerComponent.vue";
import Banner_Image from "@/assets/images/home/banner.png";
export default {
  name: "HomeView",
  components: {
    Heading,
    Banner,
  },

  data() {
    return {
      SolutionsData: Solutionjson.SolutionData,

      ImageData: [
        {
          icon: "chain-1.png",
        },
        {
          icon: "chain-2.png",
          id: "track-right",
        },
        {
          icon: "chain-3.png",
        },
      ],
      Banner_Image: Banner_Image,
    };
  },
};
</script>

<style scoped>
/**================= BANNER SEC CSS START =================**/

/* .banner-sec {
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: 90px;
}

.banner-sec::before {
  position: absolute;
  content: "";
  top: 0;
  left: 17%;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #0d202d 0,
    rgba(21, 50, 71, 0) 100%
  );
  z-index: -1;
  transform: rotate(-61deg);
}

.banner-info h2 {
  color: var(--white);
    font-size: 50px;
    line-height: 74px;
    text-transform: uppercase;
    font-weight: 600;
}



.banner-info p {
  font-size: 16px;
  line-height: 26px;
  color: var(--white);
  text-align: justify;
  font-family: "Poppins";
} */

/**================= BANNER SEC CSS FINISH =================**/

/**================= SCALEABLE SEC CSS START =================**/
.scalebale-sec {
  position: relative;
  z-index: 1;
}

.scalebale-sec::before {
  position: absolute;
  content: "";
  top: 0;
  right: 10%;
  width: 500px;
  height: 500px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 223, 255, 0.48) 0,
    rgba(0, 223, 255, 0) 100%
  );
  opacity: 0.3;
  transform: rotate(-120deg);
  z-index: -1;
}

.scaleable-info {
  position: sticky;
  top: 12%;
}

.scaleable-info h3 {
  color: var(--white);
  font-size: 35px;
  line-height: 50px;
  text-transform: capitalize;
  position: sticky;
  top: 0;
}

ul.scale-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.scale-list li {
  margin-bottom: 50px;
}

ul.scale-list li .scale-box {
  border-bottom: 1px solid var(--border);
  padding-bottom: 62px;
}

ul.scale-list li:nth-child(4) .scale-box {
  padding-bottom: 0;
  border: none;
}

ul.scale-list li .scale-box h2 {
  font-size: 80px;
  color: var(--purple);
  font-weight: 500;
}

ul.scale-list li .scale-box h2 {
  font-size: 80px;
  color: var(--purple);
  font-weight: 500;
}

ul.scale-list li .scale-box span {
  font-size: 18px;
  color: var(--grey);
}
/**================= SCALEABLE SEC CSS FINISH =================**/

/**================= WEB THREE SEC CSS START =================**/

.web-three-sec {
  position: relative;
}

.box-shape {
  position: absolute;
  transform: rotate(45deg);
  background: radial-gradient(
    50% 50% at 50% 50%,
    #00dfff 0,
    rgba(0, 223, 255, 0) 100%
  );
  opacity: 0.1;
  width: 380px;
  height: 700px;
  top: -180px;
  left: 472px;
}

.web-main-box {
  border-radius: 24px;
  height: 100%;
  background: linear-gradient(
    35.95deg,
    hsla(0, 0%, 100%, 0.05) 21.76%,
    hsla(0, 0%, 100%, 0) 80.12%
  );
  padding: 40px;
  min-height: 360px;
}

.icon-box {
  margin-bottom: 25px;
}

.icon-box img {
  max-width: 130px;
  mix-blend-mode: lighten;
}

.icon-info h4 {
  font-size: 20px;
  color: var(--white);
  font-weight: 500;
}

.icon-info p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins";
  color: var(--grey);
}
/**================= WEB THREE SEC CSS FINISH =================**/

/**================= SOLUTIONS CSS START =================**/
.solutions-sec {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.solutions-sec::before {
  position: absolute;
  content: "";
  top: 0px;
  left: calc(50% - 500px);
  width: 1000px;
  height: 1000px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #0a1b25 0,
    rgba(10, 27, 37, 0) 100%
  );
  transform: rotate(0deg);
  z-index: -1;
}

.solution-box {
  padding: 40px;
  background: linear-gradient(
    35.95deg,
    hsla(0, 0%, 100%, 0.05) 21.76%,
    hsla(0, 0%, 100%, 0) 80.12%
  );
}

.solution-icon-box {
  margin-bottom: 30px;
}

.solution-icon-box img {
  max-width: 160px;
}

.solution-icon-info h3 {
  font-size: 20px;
  color: var(--white);
  font-weight: 500;
}

.solution-icon-info p {
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins";
  color: var(--grey);
}

/**================= SOLUTIONS CSS FINISH =================**/

/**================= CONTRIBUTE CSS START =================**/

.contribute-sec {
  overflow: hidden;
}

.row.inner-card-row {
  padding: 40px;
  background: linear-gradient(
    35.95deg,
    hsla(0, 0%, 100%, 0.05) 21.76%,
    hsla(0, 0%, 100%, 0) 80.12%
  );
}

.contribute-main-box {
  text-align: center;
}

.contribute-main-box h4 {
  font-size: 20px;
  color: var(--white);
  font-weight: 500;
}

.contribute-main-box p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins";
  color: var(--grey);
}

.contribute-main-box .btn-outline-secondary {
  min-height: 50px;
  font-family: "Poppins";
  min-width: 160px;
  border-color: var(--white);
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  transition: all 0.5s ease;
}

.contribute-main-box .btn-outline-secondary:hover {
  background-color: var(--grey);
}

.border-box {
  border-left: 1px dashed var(--border);
  min-height: 100%;
}
/**================= CONTRIBUTE CSS FINISH =================**/

/**================= PARTNER CSS START =================**/

.partners-sec {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.wrapper {
  height: 80px;
  background-repeat: repeat-x;
  background-position: 50%;
  background-size: 1995px 80px;
  animation: Track_Left 400s linear infinite;
  margin-bottom: 35px;
}

@keyframes Track_Left {
  0% {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 0;
  }
}

#track-right {
  animation: Track_Right 400s linear infinite;
}

@keyframes Track_Right {
  0% {
    background-position: 0 0;
  }
  to {
    background-position: 10000px 0;
  }
}

.wrapper:nth-child(4) {
  margin-bottom: 0;
}

.partner-shape {
  position: absolute;
  opacity: 0.2;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #ffb31a 0,
    rgba(37, 10, 10, 0) 100%
  );
  width: 1681px;
  height: 931px;
  transform: rotate(-135deg);
  top: 0;
  left: calc(50% - 8px);
  z-index: -1;
}
/**================= PARTNER CSS FINISH =================**/

@media all and (min-width: 992px) and (max-width: 1199px) {
  ul.scale-list li .scale-box h2 {
    font-size: 50px;
  }

  .web-main-box {
    padding: 20px;
  }

  .icon-info p br {
    display: none;
  }

  .contribute-main-box p br {
    display: none;
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  ul.scale-list li .scale-box h2 {
    font-size: 35px;
    line-height: 62px;
  }

  .scalebale-sec::before {
    display: none;
  }

  .contribute-main-box h4 {
    font-size: 18px;
  }
}

@media all and (min-width: 320px) and (max-width: 767px) {
  .banner-info h2 {
    font-size: 17px;
    line-height: 40px;
    text-align: center;
  }

  .banner-info p {
    font-size: 15px;
    text-align: center;
  }

  .scaleable-info h3 {
    font-size: 25px;
    text-align: center;
  }

  .scaleable-info h3 br {
    display: none;
  }

  ul.scale-list li .scale-box h2 {
    font-size: 25px;
  }

  .scalebale-sec::before {
    display: none;
  }

  .scaleable-info {
    margin-bottom: 50px;
  }

  ul.scale-list li .scale-box {
    padding-bottom: 20px;
    text-align: center;
  }

  .web-main-box {
    padding: 20px;
  }

  .icon-box {
    text-align: center;
  }

  .icon-info h4 {
    font-size: 16px;
    line-height: 28px;
    text-align: center;
  }

  .icon-info p {
    text-align: center;
  }

  .card-box {
    margin-bottom: 25px;
  }

  .solution-box {
    padding: 20px;
  }

  .solution-icon-info h3 {
    font-size: 16px;
  }

  .row.inner-card-row {
    padding: 20px;
  }

  .contribute-main-box h4 {
    font-size: 17px;
  }

  .contribute-main-box p br {
    display: none;
  }
}
</style>
